<template>
  <skeleton-o-c-detail-component-global v-if="bSkeleton" />
  <div v-else class="contant-oc-detail-global global-content-detail-all">
    <div class="global-content-detail">
      <div ref="iHeaderDetail">
        <header-detail-component-global
          :sTextDetail="sTextDetail"
          :sBackHref="sBackHref"
          :setDialog="setDialog"
          :sTextBtnAdd="sTextBtnAdd"
          :bAdminPermissions="bAdminPermissions"
          :sClassBtnAdd="sClassBtnAdd"
          :oItem="oItem"
          @setDialogAdd="setDialogConfirmationOfPaid"
          @setDialogConfirmCancel="setDialogConfirmationGlobal"
        />
        <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
      </div>
      <!--#region INFORMATION DATA CONTENT -->
      <v-container class="pb-0" fluid>
        <v-divider class="divider-global"></v-divider>
        <div class="content-information-data">
          <v-expansion-panels v-model="panelInformationData" flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0" :hide-actions="true">
                <div class="content-title-information">
                  <v-icon
                    id="ArrowInformation"
                    class="icon-arrow-information"
                    color="var(--primary-color-color-title-information)"
                  >
                    mdi-chevron-right
                  </v-icon>
                  Datos informativos
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="content-infomation-data-expansion">
                  <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <p class="text-title">Folio</p>
                      <p class="text-information">
                        {{ oItem.sReference }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                      lg="4"
                      xl="4"
                      v-if="!bViewProviderOrCustomer && !bWeighingMachinePublic"
                    >
                      <p class="text-title">Nombre del {{ sTypeActor }}</p>
                      <p class="text-information">
                        <v-icon
                          v-show="bToProvider"
                          @click="goToUser(oActor.sActorId)"
                          :class="bToProvider ? 'text-icon-launch' : ''"
                          >mdi-launch</v-icon
                        >
                        <span
                          @click="goToUser(oActor.sActorId)"
                          :class="bToProvider ? 'text-information-link' : ''"
                        >
                          {{ oActor.sName }}
                        </span>
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                      lg="4"
                      xl="4"
                      v-if="!bViewProviderOrCustomer && !bWeighingMachinePublic"
                    >
                      <p class="text-title">Teléfono</p>
                      <p
                        v-if="oActor.sFullPhoneNumber"
                        class="text-information"
                      >
                        {{ oActor.sFullPhoneNumber }}
                      </p>
                      <p v-else class="text-data-empty">
                        {{ sEmptyData }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                      lg="4"
                      xl="4"
                      v-if="!bViewProviderOrCustomer && !bWeighingMachinePublic"
                    >
                      <p class="text-title">Correo electrónico</p>
                      <p v-if="oActor.sEmail" class="text-information">
                        {{ oActor.sEmail }}
                      </p>
                      <p v-else class="text-data-empty">
                        {{ sEmptyData }}
                      </p>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <p class="text-title">Fecha y hora de creación</p>
                      <p class="text-information">
                        {{ oItem.tCreatedAt }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <v-divider class="divider-global"></v-divider>
      </v-container>
      <!--#endregion INFORMATION DATA CONTENT -->

      <!--#region TRANSPORT CONTENT -->
      <v-container class="pt-0 pb-0" fluid>
        <div class="content-transport">
          <v-expansion-panels v-model="panelTransport" flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0" :hide-actions="true">
                <div class="content-title-information">
                  <v-icon
                    id="ArrowTransport"
                    class="icon-arrow-transport"
                    color="var(--primary-color-color-title-information)"
                  >
                    mdi-chevron-right
                  </v-icon>
                  Transporte
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="content-infomation-data-expansion">
                  <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <p class="text-title">Placa del vehículo</p>
                      <p class="text-information" v-if="oDriver.sLicensePlate">
                        {{ oDriver.sLicensePlate }}
                      </p>
                      <p v-else class="text-data-empty">
                        {{ sEmptyData }}
                      </p>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <p class="text-title">Nombre del transportista</p>
                      <p class="text-information">
                        {{ oDriver.sFullName }}
                      </p>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <p v-if="bWeighingMachinePublic" class="text-title">
                        Pesaje del camión (Kg)
                      </p>
                      <p v-else class="text-title">
                        Pesaje inicial del camión (Kg)
                      </p>
                      <p class="text-information d-flex">
                        <span
                          @click="setDialogImgsOP(oItem, true)"
                          class="material-icons"
                          :class="
                            oItem.dInitialWeight > 0 ? 'text-icon-launch' : ''
                          "
                        >
                          image_search
                        </span>
                        <span
                          @click="setDialogImgsOP(oItem, true)"
                          :class="
                            oItem.dInitialWeight > 0
                              ? 'text-information-link'
                              : ''
                          "
                        >
                          {{ formatMoneyGlobal(oItem.dInitialWeight) }} Kg
                        </span>
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <p class="text-title">Comentarios iniciales</p>
                      <p
                        class="text-information-global"
                        v-if="oItem.sComments !== 'Sin comentarios.'"
                      >
                        {{ oItem.sComments }}
                      </p>
                      <p v-else class="text-data-empty">
                        {{ sEmptyData }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <v-divider class="divider-global"></v-divider>
      </v-container>
      <!--#endregion TRANSPORT CONTENT -->

      <!--#region CURRENT ORDER STATUS -->
      <!-- <v-container v-if="bOPDetailCustomer" class="pt-0 pb-0" fluid>
        <div class="content-transport">
          <v-expansion-panels v-model="panelCurrentOrderStatus" flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0" :hide-actions="true">
                <div class="content-title-information">
                  <v-icon id="ArrowCurrentOrderStatus" class="icon-arrow-current-order-status"
                    color="var(--primary-color-color-title-information)">
                    mdi-chevron-right
                  </v-icon> Estado actual del pedido
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="content-current-order-status-expansion">
                  <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <p class="text-title">Estado de la operación</p>
                      <p class="text-information">
                        {{ oOrderStatus.sStatusName }}
                      </p>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <p class="text-title">Fecha y hora de cambio a estado actual</p>
                      <p class="text-information">
                        {{ oOrderStatus.tLastStatusChange }}
                      </p>
                    </v-col>

                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <v-divider class="divider-global"></v-divider>
      </v-container> -->
      <!--#endregion CURRENT ORDER STATUS -->

      <!--#region TABLE RAW MATERIALS (TEMPORAL - ALTA DE UNA OPERACIÓN Y DETALLE DE OPERACION, ESPECIALISTA Y OPERACIONES DE CAJA) -->

      <TableRawMaterialsGlobal
        v-if="!bWeighingMachinePublic"
        @getItem="getEmitOcDetail"
        @setDialogImgsOP="setDialogImgsOP"
        :isMobile="isMobile"
        :aHeadersRawMaterials="aHeadersRawMaterials"
        :aRawMaterial="aRawMaterial"
        :oItem="oItem"
        :bAdminPermissions="bAdminPermissions"
        :bCloseBtnRawMaterial="bCloseBtnRawMaterial"
        :numPages="numPages"
        :totalItems="totalItems"
        :iItemsPerPage="iItemsPerPage"
        :bLoadingTable="bLoadingTable"
      />

      <!--#endregion TABLE RAW MATERIALS -->

      <!-- #region ACCUMULATED TRANSACTION AMOUNT -->
      <v-container
        v-if="
          bOPDetailReport ||
          bOPDetailGeneral ||
          bOPPayableDetail ||
          bOperationDetails ||
          bOPTransationDetailAccount ||
          bOPDetailTransactionDetail
        "
        fluid
        class="accumulated-transaction-amount-all"
      >
        <div
          class="content-accumulated-transaction-amount-mxn content-shadow-global"
        >
          <span class="title-amount"> Monto acumulado: </span>
          <br v-if="isMobile" />
          <span class="text-amount">
            $
            {{
              formatMoneyGlobal(
                ObjEmptyGlobal(oItem)
                  ? oItem.oPrices.oMexicanPeso.dTotalAmount
                  : 0
              )
            }}
            MXN
          </span>
        </div>
        <div
          class="content-accumulated-transaction-amount-usd content-shadow-global"
        >
          <span class="title-amount"> Monto acumulado: </span>
          <br v-if="isMobile" />
          <span class="text-amount">
            $
            {{
              formatMoneyGlobal(
                ObjEmptyGlobal(oItem) ? oItem.oPrices.oUSDollar.dTotalAmount : 0
              )
            }}
            USD
          </span>
        </div>
      </v-container>

      <!-- #endregion ACCUMULATED TRANSACTION AMOUN -->

      <!-- #region PARTIAL PAYMENTS  -->
      <v-container
        v-if="
          (bOPPayableDetail ||
            bOPDetailReport ||
            bOPDetailGeneral ||
            bOperationDetails ||
            bOPTransationDetailAccount ||
            bOPDetailTransactionDetail) &&
          ObjEmptyGlobal(oItem)
        "
        class="pt-0 pb-0 mt-5"
        fluid
      >
        <v-divider class="divider-global"></v-divider>
        <div class="content-title-add-material-detail-oc">
          <p class="text-title-table">Pagos parciales</p>
          <v-spacer> </v-spacer>
          <!-- :disabled="(oItem.dTotalAmount - iTransactionsTotal) == 0"  -->
          <span v-if="bAdminPermissions">
            <v-btn
              v-if="sNameOrderStatus !== 'FINALIZADA'"
              @click="bAddTransaction = true"
              class="btn-add-global add-transaction-button mt-3 mb-3"
              :class="
                $vuetify.breakpoint.smAndDown ? 'btn-add-global-mobile' : ''
              "
            >
              <span
                class="text-btn-add-mobile"
                v-if="$vuetify.breakpoint.smAndDown"
              >
                +
              </span>
              <span v-else> + Añadir transacción </span>
            </v-btn>
          </span>
        </div>
        <partial-payments-table
          @update="getOPDetail"
          :transactions="myTransactions"
        />
        <div>
          <div
            :style="
              isMobile
                ? 'margin-bottom: 20px; padding: 10px 2px 10px 2px !important'
                : 'margin-bottom: 20px;'
            "
            class="content-transactions-amount content-shadow-global"
          >
            <!-- #region total de operación -->
            <span class="span-amount-total-transactions-holder">
              <span
                :style="isMobile ? 'font-size: 15px !important' : ''"
                class="total-transactions-text"
              >
                {{
                  sTypeActor == "proveedor" ? "Total a pagar" : "Total a cobrar"
                }}
              </span>
              <v-spacer></v-spacer>
              <span
                :style="isMobile ? 'font-size: 15px !important' : ''"
                class="total-transactions-text-amount"
              >
                $ {{ formatMoneyGlobal(iTotalOperation) }}
                {{ this.sCurrencyCodeRowsTransaction }}
              </span>
            </span>
            <!-- #endregion total de operación -->

            <!-- #region Total a pagar -->
            <span
              style="margin-top: 15px"
              class="span-amount-total-transactions-holder"
            >
              <span
                :style="isMobile ? 'font-size: 15px !important' : ''"
                class="total-transactions-text"
              >
                {{
                  sTypeActor == "proveedor" ? "Total pagado" : "Total cobrado"
                }}
              </span>
              <v-spacer></v-spacer>
              <span
                :style="isMobile ? 'font-size: 15px !important' : ''"
                class="total-transactions-text-amount"
              >
                $ {{ formatMoneyGlobal(iTransactionCobrado) }}
                {{ this.sCurrencyCodeRowsTransaction }}
              </span>
            </span>
            <!-- #endregion Total a pagar -->

            <!-- #region Total a cobrar solo en COMPRA -->
            <span
              style="margin-top: 15px"
              class="span-amount-total-transactions-holder"
            >
              <span
                :style="isMobile ? 'font-size: 15px !important' : ''"
                class="total-transactions-text"
              >
                {{
                  sTypeActor == "proveedor" ? "Total cobrado" : "Total pagado"
                }}
              </span>
              <v-spacer></v-spacer>
              <span
                :style="isMobile ? 'font-size: 15px !important' : ''"
                class="total-transactions-text-amount"
              >
                $ {{ formatMoneyGlobal(iTrasactionPagado) }}
                {{ this.sCurrencyCodeRowsTransaction }}
              </span>
            </span>
            <!-- #endregion Total a cobrar solo en COMPRA -->

            <!-- #region TOTAL  -->
            <span
              :style="
                isMobile
                  ? 'margin-top: 15px; margin-left: 3% !important; padding-left: 2% !important; padding-right: 2% !important'
                  : 'margin-top: 15px'
              "
              class="span-amount-total-transactions-holder-total"
            >
              <span
                :style="isMobile ? 'font-size: 16px !important' : ''"
                class="total-transactions-text-total"
              >
                {{
                  sTypeActor == "proveedor"
                    ? "TOTAL RESTANTE."
                    : "TOTAL DE ADEUDO."
                }}
              </span>
              <v-spacer></v-spacer>
              <span
                :style="isMobile ? 'font-size: 16px !important' : ''"
                class="total-transactions-text-total"
              >
                $ {{ formatMoneyGlobal(getTotal()) }}
                {{ this.sCurrencyCodeRowsTransaction }}
              </span>
            </span>
            <!-- #endregion TOTAL  -->
          </div>
        </div>
        <div
          :class="
            !isMobile
              ? 'content-title-add-material-detail-oc'
              : 'content-btn-primary'
          "
        >
          <v-spacer class="mobile"></v-spacer>
          <v-btn
            @click="generateBoleta()"
            :loading="bLoadingBoleta"
            :class="
              !isMobile
                ? 'btn-add-global add-transaction-button mb-3'
                : 'global-btn-primary mb-3'
            "
          >
            <span>Generar boleta</span>
          </v-btn>
        </div>
        <add-partial-payment
          @update="getOPDetail"
          @close="bAddTransaction = false"
          :sType="sTypeActor == 'cliente' ? 'venta' : 'compra'"
          :bDialogAddTransation="bAddTransaction"
          :sOrderId="oItem.sOrderId"
          :sReference="oItem.sReference"
          :iCountRowsTransaction="iCountRowsTransaction"
          :sCurrencyCode="sCurrencyIdRowsTransaction"
        ></add-partial-payment>
      </v-container>
      <!-- #endregion PARTIAL PAYMENTS  -->
    </div>
    <!-- <footer-component-global /> -->
    <dialog-confirmation-of-paid-component-global
      @setDialogConfirmationOfPaid="setDialogConfirmationOfPaid"
      :bDialogConfirmationOfPaid="bDialogConfirmationOfPaid"
      :oPurchaseOrder="oItem"
    />

    <dialog-action-confirm-global
      @setDialogActionConfirmGlobal="setDialogConfirmationGlobal"
      :bDialogActionConfirm="bDialogConfirmationGlobal"
      :oDialogConfirm="oDialogConfirmGlobal"
    />

    <dialog-imgs-o-p
      @setDialogImgsOP="setDialogImgsOP"
      :bDialogImgsOP="bDialogImgsOP"
      :oItem="oItemImgsOP"
      :bInitialImages="bInitialImages"
      :aImgsOP="aRawMaterial"
      :iCarouselRawMaterials="iCarouselRawMaterials"
    />
  </div>
</template>

<script>
export default {
  props: {
    sApiGetOC: String,
    aHeadersRawMaterials: Array,
    sTypeItem: Number,
    sTextTitle: String,
    sTextDetail: String,
    sBackHref: String,
    setDialog: String,
    sTextBtnAdd: String,
    bAdminPermissions: Boolean,
    sClassBtnAdd: String,
    itemsBreadcrumbs: Array,
    toProvider: String,
    bToProvider: { type: Boolean, default: true },
  },
  data() {
    return {
      screenHeight: 0,
      heightMax: 0,
      bAddTransaction: false,
      bSkeleton: false, //true
      bLoadingTable: true, //true
      bEmptyTable: true, //false
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      totalView: 0,
      oItem: {},
      oActor: {},
      oDriver: {},
      oOrderStatus: {},
      aRawMaterial: [],
      isMobile: false,
      bDialogConfirmationOfPaid: false,
      bDialogConfirmationRawMaterial: false,
      bDialogConfirmationGlobal: false,
      bDialogConfirmCancel: false,
      oDialogConfirmCancel: {
        active: true,
        textTitleActionConfirm: "",
        textDescriptionActionConfirm: "",
        textQuestionActionConfirm: "",
        returnToViewActionConfirm: false,
        apiActionConfirm: "",
      },
      oDialogConfirmRawMaterial: {},
      oDialogConfirmGlobal: {},
      bCreatedOrCanceled: false,
      bPaid: false,
      sTypeActor: "",
      sEmptyData: "Sin información.",
      bCloseBtnRawMaterial: false,
      panelInformationData: false,
      panelTransport: false,
      panelCurrentOrderStatus: false,
      aShowForDetailCustomer: ["OPDetailCustomer"],
      aShowCurrentOrderStatus: ["OPDetailCustomer"],
      aLimitOrderDetail: ["OPDetailProvider", "OPDetailCustomer"],
      aViewProviderOrCustomer: ["OPDetailProvider", "OPDetailCustomer"],
      bDialogImgsOP: false,
      bInitialImages: false,
      oItemImgsOP: {},
      iCarouselRawMaterials: 0,
      myTransactions: [],
      iCountRowsTransaction: 0,
      sCurrencyIdRowsTransaction: "",
      sCurrencyCodeRowsTransaction: "",
      sIdOrderStatus: "",
      sNameOrderStatus: "",
      bWeighingMachinePublic: false,
      sBoleta: "",
      bLoadingBoleta: false,
    };
  },
  beforeMount() {
    this.getOPDetail();
  },
  mounted() {
    this.matchHeight();
    this.handleResize();
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.addEventListener("resize ", this.matchHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    generateBoleta() {
      this.bLoadingBoleta = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          const payload = {},
            config = {
              headers: {
                Authorization: "Bearer " + this.$store.state.sToken,
              },
            };
          DB.get(
            `${URI}/api/sp/v1/orders/general/${this.$route.params.idOP}/ticket`,
            config,
            payload
          )
            .then((response) => {
              this.sBoleta = response.data.results;
              // window.open(this.sBoleta);
              this.getOpenPDFGlobal(this.sBoleta);
              this.bLoadingBoleta = false;
              // this.Success(response.data.message)
            })
            .catch((error) => {
              this.bLoadingBoleta = false;
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    getTotal() {
      if (this.sTypeActor == "proveedor") {
        return (
          this.iTotalOperation -
          this.iTransactionCobrado +
          this.iTrasactionPagado
        );
      } else {
        if (this.iCountRowsTransaction > 0) {
          if (
            this.sCurrencyIdRowsTransaction ===
            "cf37a729-0384-4ae0-8be8-60575e60c603"
          ) {
            return (
              this.oItem.oPrices.oMexicanPeso.dTotalAmount -
              this.iTransactionsTotal
            );
          } else {
            return (
              this.oItem.oPrices.oUSDollar.dTotalAmount -
              this.iTransactionsTotal
            );
          }
        } else {
          return 0;
        }
      }
    },
    matchHeight() {
      if (this.$refs.iHeaderDetail !== undefined) {
        this.heightMax = this.$refs.iHeaderDetail.clientHeight;
        this.heightMax = this.heightMax + 123;
      }
      if (window.innerWidth > 599) {
        this.isMobile = false;
        this.screenHeight = window.innerHeight - 100;
      } else {
        this.isMobile = true;
        this.screenHeight = window.innerHeight - 100;
      }
    },
    handleResize: function () {
      this.matchHeight();
    },
    getEmitOcDetail(obj) {
      this.getOPDetail();
    },
    getOPDetail() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          const payload = {},
            config = {
              headers: {
                Authorization: "Bearer " + this.$store.state.sToken,
              },
            };
          DB.get(
            `${this.sApiGetOC}${this.$route.params.idOP} ${
              this.sLimitedOrderDetail ? "/limited" : ""
            }`,
            config,
            payload
          )
            .then((response) => {
              this.bWeighingMachinePublic =
                response.data.results.sType === "Báscula pública";
              this.oItem = response.data.results;
              this.sTypeActor =
                this.oItem.sType === "Orden de compra"
                  ? "proveedor"
                  : "cliente";
              this.oActor = this.oItem.oActor;
              this.$store.commit("setTypeActor", this.oActor.sType);
              this.oDriver = this.oItem.oDriver;
              this.oOrderStatus = this.oItem.oOrderStatus;
              this.sIdOrderStatus =
                response.data.results.oOrderStatus.sStatusId;
              this.sNameOrderStatus = response.data.results.oOrderStatus.sName;
              if (
                this.bOPDetailReport ||
                this.bOPDetailGeneral ||
                this.bOPPayableDetail ||
                this.bOperationDetails ||
                this.bOPTransationDetailAccount ||
                this.bOPDetailTransactionDetail
              ) {
                this.myTransactions = response.data.results.arrTransactions.map(
                  (e) => {
                    return {
                      ...e,
                      sTransaction:
                        e.eTransactionType === "DEPOSIT" ? "Ingreso" : "Egreso",
                    };
                  }
                );
                this.iCountRowsTransaction = this.myTransactions.length;
                if (this.iCountRowsTransaction > 0) {
                  this.sCurrencyIdRowsTransaction =
                    this.myTransactions[
                      this.iCountRowsTransaction - 1
                    ].sCurrencyId;
                  this.sCurrencyCodeRowsTransaction =
                    this.myTransactions[
                      this.iCountRowsTransaction - 1
                    ].sCurrencyCode;
                }
              }
              this.$store.commit(
                "setsNameDetail",
                "" + this.oItem.sType + ": " + this.oItem.sReference
              );
              if (!this.bWeighingMachinePublic) {
                this.getRawMaterials();
              }
            })
            .catch((error) => {
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    getEmitRawMaterials(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.iItemsPerPage = obj.iItemsPerPage;
      this.getRawMaterials();
    },
    getRawMaterials() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          let sLimitedOrCompleted = this.getLimitedOrCompleted();
          DB.get(`${sLimitedOrCompleted}`, {
            params: {
              iPageNumber: this.iCurrentPage,
              iItemsPerPage: this.iItemsPerPage,
            },
            headers: {
              Authorization: "Bearer " + this.$store.state.sToken,
            },
          })
            .then((response) => {
              this.numPages = response.data.numPages;
              this.totalItems = response.data.total;
              this.aRawMaterial = response.data.results.map((e, i) => {
                return {
                  ...e,
                  i: i,
                  dNumTransation: i + 1,
                  sColorKeyRawMaterial: e.oRawMaterial.sColorKey,
                  sCodeRawMaterial: e.oRawMaterial.sCode,
                  sNameRawMaterial: e.oRawMaterial.sName,
                  dWeightRawMaterial: e.dNetWeight ? e.dNetWeight : 0,
                  sMeasurementUnitRawMaterial: e.oMeasurementUnit.sCode,
                  dUnitPriceRawMaterial: 0,
                  dUnitPriceRawMaterialMXN: e.oPrices
                    ? e.oPrices.oMexicanPeso.oUnitPrice.dUnitPrice
                    : 0,
                  dUnitPriceRawMaterialUSD: e.oPrices
                    ? e.oPrices.oUSDollar.oUnitPrice.dUnitPrice
                    : 0,
                  dSubtotalRawMaterial: 0,
                  dSubtotalRawMaterialMXN: e.oPrices
                    ? e.oPrices.oMexicanPeso.oSubtotal.dSubtotal
                    : 0,
                  dSubtotalRawMaterialUSD: e.oPrices
                    ? e.oPrices.oUSDollar.oSubtotal.dSubtotal
                    : 0,
                  sManagerRawMaterial: e.oSpecialist.sFullname,
                };
              });
              this.totalView = this.aRawMaterial.length;
              this.bSkeleton = false;
              this.bLoadingTable = false;
              this.bEmptyTable = this.aRawMaterial.length < 1;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    getLimitedOrCompleted() {
      switch (this.$route.name) {
        case "OPDetailReport":
          return `${this.sApiGetOC}${this.$route.params.idOP}/items/completed`;
        case "OPGeneralDetail":
          return `${this.sApiGetOC}${this.$route.params.idOP}/items/completed`;
        case "OPPendingDetail":
          return `${URI}/api/sp/v1/orders/general/${this.$route.params.idOP}/items/limited`;
        case "OPPayableDetail":
          return `${URI}/api/sp/v1/orders/general/${this.$route.params.idOP}/items/completed`;
        case "OperationDetails":
          return `${URI}/api/sp/v1/orders/general/${this.$route.params.idOP}/items/completed`;
        case "OPTransationDetailAccount":
          return `${URI}/api/sp/v1/orders/general/${this.$route.params.idOP}/items/completed`;
        case "OPDetailTransactionDetail":
          return `${URI}/api/sp/v1/orders/general/${this.$route.params.idOP}/items/completed`;
        default:
          return `${this.sApiGetOC}${this.$route.params.idOP}/items/limited`;
      }
      // if (
      //   this.$route.name === "OPGeneralDetail" ||
      //   this.$route.name === "OPPayableDetail"
      // ) {
      //   return "completed";
      // } else {
      //   return "limited";
      // }
    },
    goToUser(sUserId) {
      if (this.bToProvider) {
        this.$router.push(this.toProvider + `/${sUserId}`).catch(() => {});
      }
    },
    setDialogImgsOP(Item, bInitialImages) {
      if (Item !== null) {
        if (bInitialImages) {
          if (Item.dInitialWeight > 0) {
            this.bDialogImgsOP = true;
          }
        } else {
          if (Item.dWeightRawMaterial > 0) {
            this.bDialogImgsOP = true;
          } else {
            let ObjMessage = {
              code: null,
              message: "No hay imágenes que mostrar.",
            };
            this.Error(ObjMessage);
          }
        }
        this.bInitialImages = bInitialImages;
        this.oItemImgsOP = Item;
        this.iCarouselRawMaterials = Item.i;
      } else {
        this.bDialogImgsOP = false;
      }
    },
    setDialogConfirmationOfPaid() {
      this.bDialogConfirmationOfPaid = !this.bDialogConfirmationOfPaid;
    },
    setDialogConfirmCancel() {
      this.oDialogConfirmCancel = {
        active: true,
        textTitleActionConfirm: "Cancelar órden de compra",
        textDescriptionActionConfirm:
          "La cancelación de una orden de compra es una acción irreversible. ",
        textQuestionActionConfirm: `Acepto la responsabilidad y deseo proseguir con la cancelación de la orden de compra: ${this.oItem.sReference} `,
        returnToViewActionConfirm: false,
        apiActionConfirm: `${URI}/api/sp/v1/purchase-orders/general/${this.$route.params.idOP}/`,
        iTypeAction: 4, //1= Post , 2= put , 3= patch , 4=delete , 5 = get
        oParams: {},
      };
      this.bDialogConfirmCancel = !this.bDialogConfirmCancel;
    },
    setDialogConfirmationAddRawMaterial(sValueRawMaterial) {
      this.oDialogConfirmRawMaterial = {
        active: true,
        textTitleActionConfirm: "Agregar materia prima",
        textDescriptionActionConfirm:
          "El agregar una materia prima a una orden de compra es una acción irreversible.",
        textQuestionActionConfirm: `Acepto la responsabilidad y deseo proseguir con el agregado de una materia a la orden de compra: ${this.oItem.sReference} `,
        returnToViewActionConfirm: true,
        apiActionConfirm: `${URI}/api/sp/v1/purchase-orders/pending-identify/${this.$route.params.idOP}`,
        iTypeAction: 1, //1= Post , 2= put , 3= patch , 4=delete , 5 = get
        oParams: {
          sRawMaterialId: sValueRawMaterial,
        },
      };
      this.bDialogConfirmationGlobal = !this.bDialogConfirmationRawMaterial;
    },
    setDialogConfirmationGlobal(oItem) {
      this.oDialogConfirmGlobal = oItem;
      if (this.oDialogConfirmGlobal === undefined) {
        this.bCloseBtnRawMaterial = !this.bCloseBtnRawMaterial;
      }
      this.bDialogConfirmationGlobal = !this.bDialogConfirmationGlobal;
    },
    // FUNCTION OF FOOTER TABLE
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getRawMaterials();
    },
    backPage() {
      if (this.iCurrentPage > 1) {
        this.aTable = [];
        this.bLoadingTable = true;
        this.iCurrentPage--;
      }
    },
    nextPage() {
      if (this.iCurrentPage < this.numPages) {
        this.aTable = [];
        this.bLoadingTable = true;
        this.iCurrentPage++;
      }
    },
    firstPage() {
      this.iCurrentPage = 1;
    },
    lastPage() {
      this.iCurrentPage = this.numPages;
    },
  },
  computed: {
    iTotalOperation() {
      if (this.iCountRowsTransaction > 0) {
        if (
          this.sCurrencyIdRowsTransaction ===
          "cf37a729-0384-4ae0-8be8-60575e60c603"
        ) {
          return this.ObjEmptyGlobal(this.oItem)
            ? this.oItem.oPrices.oMexicanPeso.dTotalAmount
            : 0;
        } else {
          return this.ObjEmptyGlobal(this.oItem)
            ? this.oItem.oPrices.oUSDollar.dTotalAmount
            : 0;
        }
      } else {
        return 0;
      }
    },
    iTrasactionPagado() {
      let iNum = 0;
      // mark in purchases
      for (let i = 0; i < this.myTransactions.length; i++) {
        const element = this.myTransactions[i];
        if (this.sTypeActor == "proveedor") {
          if (element.dAmount > 0) {
            iNum = iNum + element.dAmount;
          }
        } else {
          if (element.dAmount < 0) {
            iNum = iNum + element.dAmount;
          }
        }
      }
      return Math.abs(iNum);
    },
    iTransactionCobrado() {
      let iNum = 0;
      // mark in purchases
      for (let i = 0; i < this.myTransactions.length; i++) {
        const element = this.myTransactions[i];
        if (this.sTypeActor == "proveedor") {
          if (element.dAmount < 0) {
            iNum = iNum + element.dAmount;
          }
        } else {
          if (element.dAmount > 0) {
            iNum = iNum + element.dAmount;
          }
        }
      }
      return Math.abs(iNum);
    },
    iTransactionsTotal() {
      return this.sTypeActor == "cliente"
        ? // mark in sales
          this.myTransactions.reduce((accumulator, object) => {
            return accumulator + object.dAmount;
          }, 0)
        : // mark in purchases
          this.myTransactions.reduce((accumulator, object) => {
            return accumulator - object.dAmount;
          }, 0);
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    bShowCurrentOrderStatus() {
      return (
        this.aShowCurrentOrderStatus.filter((e) => e === this.$route.name)
          .length > 0
      );
    },
    bOPPendingDetail() {
      return this.$route.name === "OPPendingDetail";
    },
    bOPDetailCustomer() {
      return this.$route.name === "OPDetailCustomer";
    },
    bOPDetailReport() {
      return this.$route.name === "OPDetailReport";
    },
    bOPDetailGeneral() {
      return this.$route.name === "OPGeneralDetail";
    },
    bOPPayableDetail() {
      return this.$route.name === "OPPayableDetail";
    },
    bOperationDetails() {
      return this.$route.name === "OperationDetails";
    },
    bOPTransationDetailAccount() {
      return this.$route.name === "OPTransationDetailAccount";
    },
    bOPDetailTransactionDetail() {
      return this.$route.name === "OPDetailTransactionDetail";
    },
    sLimitedOrderDetail() {
      return (
        this.aLimitOrderDetail.filter((e) => e === this.$route.name).length > 0
      );
    },
    bViewProviderOrCustomer() {
      return (
        this.aViewProviderOrCustomer.filter((e) => e === this.$route.name)
          .length > 0
      );
    },
  },
  watch: {
    refreshTable: function () {
      this.getOPDetail();
    },
    iCurrentPage() {
      this.getRawMaterials();
    },
    panelInformationData() {
      let arrowInformation = document.getElementById("ArrowInformation");
      if (this.panelInformationData === 0) {
        arrowInformation.classList.add("icon-arrow-informationOpen");
      } else {
        arrowInformation.classList.remove("icon-arrow-informationOpen");
      }
    },
    panelTransport() {
      let arrowTransport = document.getElementById("ArrowTransport");
      if (this.panelTransport === 0) {
        arrowTransport.classList.add("icon-arrow-transportOpen");
      } else {
        arrowTransport.classList.remove("icon-arrow-transportOpen");
      }
    },
    panelCurrentOrderStatus() {
      let arrowCurrentOrderStatus = document.getElementById(
        "ArrowCurrentOrderStatus"
      );
      if (this.panelCurrentOrderStatus === 0) {
        arrowCurrentOrderStatus.classList.add(
          "icon-arrow-current-order-statusOpen"
        );
      } else {
        arrowCurrentOrderStatus.classList.remove(
          "icon-arrow-current-order-statusOpen"
        );
      }
    },
  },
  components: {
    PartialPaymentsTable: () => import("./PartialPaymentsTable.vue"),
    AddPartialPayment: () => import("./AddPartialPayments.vue"),
  },
};
</script>

<style scoped>
.content-title-add-material-detail-oc {
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
}

.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}

.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}

.text-title-table {
  text-align: start;
  letter-spacing: 4.8px;
  margin-top: 15px;
  margin-bottom: 25px;
  font-size: 14px;
  /* margin-right: 10px; */
  font-family: "pop-Bold";
  color: var(--primary-color-color-title-information);
}

.content-btn-add-material-detail-oc {
  width: 200px;
}

.content-color-id-raw-material {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
}

.color-raw-material {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  margin-top: -1px;
  margin-right: 10px;
  border: 1px solid var(--primary-color-text);
}

.text-icon-launch {
  color: var(--primary-color-link);
  /* margin-top: -5px; */
  font-size: 20px;
  cursor: pointer;
}

.content-title-information {
  letter-spacing: 4.8px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  font-family: "pop-Bold";
  color: var(--primary-color-color-title-information);
}

.content-infomation-data-expansion {
  height: auto;
}

.v-expansion-panel {
  background-color: transparent !important;
}

.icon-arrow-information {
}

.icon-arrow-informationOpen {
  transform: rotate(-4.7rad);
}

.icon-arrow-transport {
}

.icon-arrow-transportOpen {
  transform: rotate(-4.7rad);
}

.icon-arrow-current-order-statusOpen {
  transform: rotate(-4.7rad);
}
.accumulated-transaction-amount-all {
  display: flex;
  width: 100%;
}
.content-accumulated-transaction-amount-mxn {
  background-color: var(--primary-color-background-table);
  color: var(--primary-color-text);
  height: 70px;
  width: 50%;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  text-align: end;
  align-items: center;
  margin-right: 10px;
  padding: 10px;
}
.content-accumulated-transaction-amount-usd {
  width: 50%;
  background-color: var(--primary-color-background-table);
  color: var(--primary-color-text);
  height: 70px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  text-align: end;
  align-items: center;
  margin-left: 10px;
  padding: 10px;
}

.content-transactions-amount {
  background-color: var(--primary-color-background-table);
  color: var(--primary-color-text);
  height: auto;
  border-radius: 10px;
  padding: 20px;
}

.span-amount-total-transactions-holder {
  display: flex;
  max-width: 95%;
  padding-left: 5%;
}

.span-amount-total-transactions-holder-total {
  padding-bottom: 8px;
  padding-top: 8px;
  padding-right: 1%;
  margin-right: 4%;
  margin-left: 4%;
  display: flex;
  max-width: 95%;
  padding-left: 1%;
  border-radius: 10px;
  background-color: var(--primary-color-border-input);
}

.total-transactions-text {
  font-family: "pop-Regular";
  font-size: 16px;
  color: var(--primary-color-text);
  font-weight: bold;
}

.total-transactions-text-amount {
  font-family: "pop-Regular";
  font-size: 16px;
  color: var(--primary-color-text);
}

.total-transactions-text-total {
  font-family: "pop-Regular";
  font-size: 20px;
  color: var(--primary-color-text);
  font-weight: bold;
}

.total-transactions-text-amount-total {
  font-family: "pop-Regular";
  font-size: 20px;
  color: var(--primary-color-text);
  font-weight: bold;
}

.title-amount {
  font-family: "pop-Regular";
  font-size: 28px;
  color: var(--primary-color-text-yellow);
}

.text-amount {
  font-family: "pop-Regular";
  font-size: 24px;
  color: var(--primary-color-text);
  margin-left: 10px;
}

.add-transaction-button {
  width: auto;
  height: 50px !important;
  letter-spacing: 0px !important;
  background: var(--primary-color-btn-primary) !important;
  text-transform: none !important;
  font-family: "pop-SemiBold" !important;
  border-radius: 10px !important;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .mobile {
    display: none;
  }
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .accumulated-transaction-amount-all {
    display: block;
    width: 100%;
  }
  .content-accumulated-transaction-amount-mxn {
    width: 100%;
    background-color: var(--primary-color-background-table);
    color: var(--primary-color-text);
    height: auto;
    border-radius: 10px;
    display: block;
    justify-content: flex-end;
    text-align: end;
    align-items: center;
    padding: 10px;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .content-accumulated-transaction-amount-usd {
    width: 100%;
    background-color: var(--primary-color-background-table);
    color: var(--primary-color-text);
    height: auto;
    border-radius: 10px;
    display: block;
    justify-content: flex-end;
    text-align: end;
    align-items: center;
    margin-left: 0px;
    padding: 10px;
  }

  .title-amount {
    font-family: "pop-Regular";
    font-size: 28px;
    color: var(--primary-color-text-yellow);
  }

  .text-amount {
    font-family: "pop-Regular";
    font-size: 30px;
    color: var(--primary-color-text);
    margin-left: 0px;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
